import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationInviteUserOnBoardArgs } from '../../generated/types';

export const INVITE_USER_ON_BOARD = gql`
  mutation InviteUserOnBoard($userUuid: String!, $boardUuid: String!, $data: EmailInputData!) {
    inviteUserOnBoard(userUuid: $userUuid, boardUuid: $boardUuid, data: $data)
  }
`;

export const useInviteUserOnBoard = (
  options?: MutationHookOptions<Pick<Mutation, 'inviteUserOnBoard'>, MutationInviteUserOnBoardArgs>
) => useMutation<Pick<Mutation, 'inviteUserOnBoard'>, MutationInviteUserOnBoardArgs>(INVITE_USER_ON_BOARD, options);
